import React from "react";
import {
  message,
  Button,
  Select,
  Tag,
  Spin,
  Icon,
  Avatar,
  Row,
  Col
} from "antd";
import { withFirebase } from "../Firebase";

import { CULINARY_API } from "../../constants/apis";
import allergies from "../../mocks/allergiesAll";
import diets from "../../mocks/dietsAll";

const Option = Select.Option;

class Profile extends React.Component {
  state = {
    user: null,
    allergies: [],
    diets: [],
    ingredients: [],
    userDiets: [],
    userAllergies: [],
    userDislikes: [],
    selectedDislike: null,
    loading: true,
    loadingFetch: true,
    addingAllergy: false,
    addingDiet: false
  };

  componentDidMount() {
    // get diets list
    this.setState({ diets: diets.data });

    // get allergies list
    this.setState({ allergies: allergies.data });

    // fetch all ingredients from Culinary API
    fetch(`${CULINARY_API.URL}/ingredients?lang=EN`, {
      headers: {
        Authorization: `Basic ${btoa(
          `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
        )}`,
        Accept: "application/json"
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ ingredients: json.data, loadingFetch: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ loadingFetch: false });
      });

    // get user info from firebase
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db.ref(`/users/${userId}`).on("value", snapshot => {
      const userObj = snapshot.val();
      const allergies = userObj.allergies
        ? Object.keys(userObj.allergies).map(key => {
            let allergy = userObj.allergies[key];
            allergy.key = key;
            return allergy;
          })
        : [];

      const diets = userObj.diets
        ? Object.keys(userObj.diets).map(key => {
            let diet = userObj.diets[key];
            diet.key = key;
            return diet;
          })
        : [];

      const dislikes = userObj.dislikes
        ? Object.keys(userObj.dislikes).map(key => {
            let dislike = userObj.dislikes[key];
            dislike.key = key;
            return dislike;
          })
        : [];

      this.setState({
        user: userObj,
        userDiets: diets,
        userAllergies: allergies,
        userDislikes: dislikes,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db.ref(`/users/${userId}`).off();
  }

  handleDislikeChange = dislikeId => {
    this.setState({
      selectedDislike: this.state.ingredients.find(it => it.id === dislikeId)
    });
  };

  handleOpenDietCard = () => {
    this.setState({ addingDiet: !this.state.addingDiet });
  };

  handleOpenAllergiesCard = () => {
    this.setState({ addingAllergy: !this.state.addingAllergy });
  };

  handleAddDiet = dietId => {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    const newDietKey = firebase.db
      .ref(`/users/${userId}`)
      .child("diets")
      .push().key;
    let updates = {};
    const selectedDiet = this.state.diets.find(it => it.id === dietId);
    updates[`/diets/${newDietKey}`] = selectedDiet;
    firebase.db.ref(`/users/${userId}`).update(updates);
  };

  handleAddAllergy = allergyId => {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    const newAllergyKey = firebase.db
      .ref(`/users/${userId}`)
      .child("allergies")
      .push().key;
    let updates = {};
    const selectedAllergy = this.state.allergies.find(
      it => it.id === allergyId
    );
    updates[`/allergies/${newAllergyKey}`] = selectedAllergy;
    firebase.db.ref(`/users/${userId}`).update(updates);
  };

  handleAddDislikeClick = () => {
    if (
      this.state.selectedDislike &&
      !this.state.userDislikes.find(
        it => it.id === this.state.selectedDislike.id
      )
    ) {
      const { firebase } = this.props;
      const userId = firebase.auth.currentUser.uid;
      const newDislikeKey = firebase.db
        .ref(`/users/${userId}`)
        .child("dislikes")
        .push().key;
      let updates = {};
      updates[`/dislikes/${newDislikeKey}`] = this.state.selectedDislike;
      firebase.db.ref(`/users/${userId}`).update(updates);
      console.log(
        `Added dislike: ${JSON.stringify(this.state.selectedDislike, null, 2)}`
      );
      this.setState({ selectedDislike: null });
    } else {
      message.error("Dislike not added, either not selected or duplicated...");
    }
  };

  handleDietTagClose(key) {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db.ref(`/users/${userId}/diets/${key}`).remove();
  }

  handleAllergyTagClose(key) {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db.ref(`/users/${userId}/allergies/${key}`).remove();
  }

  handleDislikeTagClose(dislikeKey) {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db.ref(`/users/${userId}/dislikes/${dislikeKey}`).remove();
  }

  render() {
    const {
      user,
      userDiets,
      userAllergies,
      userDislikes,
      diets,
      allergies,
      ingredients,
      loading,
      loadingFetch,
      addingAllergy,
      addingDiet
    } = this.state;

    const ingredientsOptions = ingredients.map(ingredient => {
      return (
        <Option key={ingredient.id} value={ingredient.id}>
          {ingredient.name}
        </Option>
      );
    });

    const dietsTags = userDiets.map(diet => {
      return (
        <Tag
          key={diet.key}
          closable
          onClose={() => this.handleDietTagClose(diet.key)}
        >
          {diet.name}
        </Tag>
      );
    });

    const allergiesTags = userAllergies.map(allergy => {
      return (
        <Tag
          key={allergy.key}
          closable
          onClose={() => this.handleAllergyTagClose(allergy.key)}
        >
          {allergy.name}
        </Tag>
      );
    });

    const loadingIcon = <Icon type="loading" spin />;

    const dislikesTags = userDislikes.map(dislike => {
      return (
        <Tag
          key={dislike.key}
          closable
          onClose={() => this.handleDislikeTagClose(dislike.key)}
        >
          {dislike.name}
        </Tag>
      );
    });

    const dietsIcons = diets
      .filter(dt => !userDiets.find(it => dt.id === it.id))
      .map(diet => {
        return (
          <Col key={diet.id} span="3">
            <button
              style={{
                textAlign: "center",
                border: "none",
                padding: 0,
                margin: 0,
                cursor: "pointer",
                outline: 0,
                background: "white"
              }}
              onClick={() => this.handleAddDiet(diet.id)}
            >
              <Avatar size={48}>{diet.name.substring(0, 1)}</Avatar>
              <p>{diet.name}</p>
            </button>
          </Col>
        );
      });

    const allergiesIcons = allergies
      .filter(al => !userAllergies.find(it => al.id === it.id))
      .map(allergy => {
        return (
          <Col key={allergy.id} span="3">
            <button
              style={{
                textAlign: "center",
                border: "none",
                padding: 0,
                margin: 0,
                cursor: "pointer",
                outline: 0,
                background: "white"
              }}
              onClick={() => this.handleAddAllergy(allergy.id)}
            >
              <Avatar size={48}>{allergy.name.substring(0, 1)}</Avatar>
              <p>{allergy.name}</p>
            </button>
          </Col>
        );
      });

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div style={{ width: 700 }}>
            <Row type="flex" align="middle">
              <Col span="5">
                <Avatar
                  size={124}
                  src="https://www.doggyandthecity.com/wp-content/uploads/2014/05/meanswear-dog.jpg"
                />
              </Col>
              <Col span="19">
                <Row>
                  <Col span="24">
                    <h1 style={{ margin: 0 }}>{user.username}</h1>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col span="24">
                    <h3>{user.email}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col span="24">
                    <p>
                      👉  On the quest to find the best Spaghetti Carbonara in
                      the world 🍝
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <h2 style={{ paddingTop: 10 }}>Dietary Preferences</h2>

            <h3>
              <Button
                type="primary"
                shape="circle"
                icon={addingDiet ? "minus" : "plus"}
                style={{ marginRight: 10 }}
                onClick={() => this.handleOpenDietCard()}
              />
              Diets: {userDiets.length > 0 ? dietsTags : "None"}
            </h3>
            {addingDiet && <Row>{dietsIcons}</Row>}

            <h3>
              <Button
                type="primary"
                shape="circle"
                icon={addingAllergy ? "minus" : "plus"}
                style={{ marginRight: 10 }}
                onClick={() => this.handleOpenAllergiesCard()}
              />
              Allergies: {userAllergies.length > 0 ? allergiesTags : "None"}
            </h3>
            {addingAllergy && <Row>{allergiesIcons}</Row>}

            <h3>
              Disliked Ingredients:{" "}
              {userDislikes.length > 0 ? dislikesTags : "None"}
            </h3>

            <div style={{ marginBottom: 10 }}>
              {loadingFetch && (
                <Spin indicator={loadingIcon} style={{ marginRight: 5 }} />
              )}
              <Select
                disabled={loadingFetch}
                defaultValue="None"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: 200 }}
                onChange={this.handleDislikeChange}
              >
                {ingredientsOptions}
              </Select>
              <Button
                disabled={loadingFetch}
                type="primary"
                onClick={this.handleAddDislikeClick}
                style={{ marginLeft: 10 }}
              >
                Add
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(Profile);

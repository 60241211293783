import React, { Component } from "react";
import { withKitchenQAApi } from "../KitchenQAApi";
import {
  Card,
  Button,
  Input,
  Form,
  Spin,
  message,
  Icon,
  AutoComplete,
  Row,
  Col
} from "antd";
import FormItem from "antd/lib/form/FormItem";

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

const rowStyle = {
  padding: "5px"
};

const cardStyle = {
  height: "100%"
};

const optionsDataSource = [
  {
    title: 'Storage & shelf life',
    children: [
      {
        title: 'how can i store',
      }, {
        title: 'where can i store',
      }, {
        title: 'how long can i store',
      }, {
        title: 'can i store',
      }
    ],
  },
  {
    title: 'Nutrition facts',
    children: [
      {
        title: 'what are the nutrition facts of',
      }, {
        title: 'how many',
      }, {
        title: 'how much',
      }, {
        title: 'how many calories are in',
      }, {
        title: 'how much sugar is in',
      }
    ],
  }
];




class KitchenQA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: null,
      question: '',
      loading: false,
      loadingCounter: 0
    };
  }

  handleError = () => {
    message.error('Failed to fetch information from NLU API');
    this.setState({
      loading: false,
      loadingCounter: 0
    });
  }

  onFetchResponse = event => {
    event.preventDefault();
    this.loadingStart();

    this.props.kitchenQAApi.doFetchResponse(this.state.question).then(data => {
      this.setState({ response: data });
      console.log('Response:', data)
      this.loadingStop();
    }, reason => {
      console.log('Fetching failed, reason:', reason);
      this.handleError();
      this.loadingStop();
    });
  };

  loadingStart = () => {
    this.setState({ loading: true });
  };

  loadingStop = () => {
    this.setState({
      loading: false
    });
  };

  handleChange(value) {
    this.setState({ question: value });
  }
  render() {

    const options = optionsDataSource.map(group => {
      return <OptGroup
        key={group.title}
        label={group.title}
      >
        {group.children.map(opt => (
          <Option key={opt.title} value={opt.title}>
            {opt.title}
          </Option>
        ))}
      </OptGroup>
    });

    let response
    if (this.state.response) {
      response = (
        <div>
          <Row style={rowStyle}>
            <Col span={24}>
              <Card title="Here's your response">
                <h2>{this.state.response.text}</h2>
              </Card>
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div>
        <h1>Kitchen Q&A</h1>
        <Spin size="large" spinning={this.state.loading}>
          <Form onSubmit={this.onFetchResponse}>
            <FormItem>
              <div className="certain-category-search-wrapper">
                <AutoComplete
                  className="certain-category-search"
                  dropdownClassName="certain-category-search-dropdown"
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 300 }}
                  size="large"
                  style={{ width: '100%' }}
                  dataSource={options}
                  optionLabelProp="value"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.question}
                  allowClear={true}
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) => {
                    if (option.key.includes(input)) return true;
                    else return false;
                  }}
                >
                  <Input
                    placeholder="What would you like to know?"
                  />
                </AutoComplete>
              </div>
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormItem>
          </Form>
          {response}
        </Spin>
      </div>
    );
  }
}

export default withKitchenQAApi(KitchenQA);

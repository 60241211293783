import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import SignInPage from "../SignIn";
import SignUpPage from "../SignUp";

import Navigation from "../Navigation";

import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    const { authUser } = this.state;
    return (
      <div>
        {authUser && <Navigation authUser={authUser} />}
        {authUser ? (
          <Redirect to={ROUTES.HOME} />
        ) : (
          <Redirect to={ROUTES.SIGN_IN} />
        )}
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      </div>
    );
  }
}

export default withFirebase(App);

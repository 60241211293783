export const CULINARY_API = {
  URL:
    "http://bsh-e-publi-13knp29c0t6x6-1433532885.eu-central-1.elb.amazonaws.com/culinary-api",
  USER: "admin",
  PASSWORD: "123581320"
};

export const CULINARY_API_DEV = {
  URL:
    "http://dev-b-devpu-fj6ms33qllwk-2053748963.eu-central-1.elb.amazonaws.com/culinary-api",
  USER: "admin",
  PASSWORD: "123581320"
};

export const NLG_API = {
  URL: "http://ec2-52-59-95-136.eu-central-1.compute.amazonaws.com/recipe",
  USER: "nlg_user",
  PASSWORD: "nlg123"
};

export const RECIPE_MIXER_API = {
  URL: "http://ec2-18-192-10-52.eu-central-1.compute.amazonaws.com"
};
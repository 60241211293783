import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";

import { Card, Col, Row } from "antd";
import dishImages from "../../mocks/dishImages";

const { Meta } = Card;

class MyRecipes extends React.Component {
  state = {
    recipes: [],
    loading: true
  };

  componentDidMount() {
    const { firebase } = this.props;

    const userId = firebase.auth.currentUser.uid;

    // get recipes list from firebase by user ID
    firebase.db
      .ref(`/users/${userId}/recipes`)
      .once("value")
      .then(snapshot => {
        const recipesObject = snapshot.val();

        if (recipesObject) {
          // get recipes list
          let recipesList = Object.keys(recipesObject).map(key => ({
            ...recipesObject[key],
            uid: key
          }));

          // get sample images for recipes by dish model ID
          recipesList = recipesList.map(recipe => {
            recipe.image = dishImages.data.find(
              it => it.id === recipe.dishModel.id
            );
            return recipe;
          });

          this.setState({
            recipes: recipesList,
            loading: false
          });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  render() {
    const { recipes, loading } = this.state;
    let cols = [];
    let rows = [];
    cols =
      recipes.length > 0
        ? recipes.map(recipe => {
            return (
              <Col span={8} key={`${recipe.id}|${recipe.name}`}>
                <Link
                  to={{
                    pathname: `/recipes/${recipe.id}`,
                    state: { modified: true, recipe: recipe }
                  }}
                >
                  <Card
                    hoverable
                    style={{ marginBottom: 16 }}
                    cover={
                      <img
                        alt={recipe.image ? recipe.image.alt : ""}
                        src={recipe.image ? recipe.image.url : ""}
                      />
                    }
                  >
                    <Meta title={recipe.name} />
                  </Card>
                </Link>
              </Col>
            );
          })
        : [];
    let colsBuffer = [];
    const colsCount = 3;
    rows = cols.map((col, index, cols) => {
      if ((index + 1) % colsCount === 0) {
        colsBuffer.push(col);
        const row = (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
        colsBuffer = [];
        return row;
      } else if (cols.length === index + 1) {
        colsBuffer.push(col);
        return (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
      } else {
        colsBuffer.push(col);
      }
    });
    return (
      <div>
        <h1>My recipes</h1>
        {!loading ? (
          <div>{rows.length > 0 ? rows : <p>No recipes saved yet...</p>}</div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  }
}

export default withFirebase(MyRecipes);

import React from "react";
import { Icon, Steps } from "antd";

const Step = Steps.Step;

class PersonalizedRecipeSteps extends React.Component {
  render() {
    const { loading, current, customizeStatus } = this.props;
    return (
      <Steps current={current} style={{ padding: 30 }}>
        <Step
          title="Recipes"
          description="Explor all recipes"
          icon={
            <Icon type={loading && current === 0 ? "loading" : "compass"} />
          }
        />
        <Step
          title="Personalize"
          description="Personalize recipe"
          icon={<Icon type={loading && current === 1 ? "loading" : "edit"} />}
          status={customizeStatus}
        />
        <Step
          title="Recipe"
          description="Enjoy!"
          icon={<Icon type={loading && current === 2 ? "loading" : "smile"} />}
        />
      </Steps>
    );
  }
}

export default PersonalizedRecipeSteps;

import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import * as ROUTES from "../../constants/routes";
import "./home.css";
import { ReactComponent as Logo } from "./empty-state.svg";

class Home extends React.Component {
  render() {
    return (
      <div className="full-width">
        <div className="empty-state">
          <Logo style={{ fill: "lightgrey", height: 170, marginBottom: 20 }} />
          <h3>Welcome to BSH Chef's Brain app</h3>
          <p>To create recipes and explore possibilites</p>
          <Link to={ROUTES.BUILD_RECIPE}>
            <Button type="primary">Click here</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Home;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";
import NluApi, { NluApiContext } from "./components/NluApi";
import KitchenQAApi, { KitchenQAApiContext } from "./components/KitchenQAApi";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <NluApiContext.Provider value={new NluApi()}>
      <KitchenQAApiContext.Provider value={new KitchenQAApi()}>
        <Router>
          <App />
        </Router>
      </KitchenQAApiContext.Provider>
    </NluApiContext.Provider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

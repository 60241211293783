import React from "react";

const NluApiContext = React.createContext(null);

export const withNluApi = Component => props => (
  <NluApiContext.Consumer>
    {nluApi => <Component {...props} nluApi={nluApi} />}
  </NluApiContext.Consumer>
);

export default NluApiContext;

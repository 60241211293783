import React from "react";
import { Link } from "react-router-dom";
import { List, Row, Col, Button, Icon, Input } from "antd";

import { CULINARY_API } from "../../constants/apis";
import PersonalizeRecipeSteps from "../PersonalizeRecipeSteps";

class CustomizeRecipe extends React.Component {
  state = {
    replacements: [],
    recipeName: "",
    selectedSubstitutes: [],
    selectedIngredient: null,
    loading: true,
    allergens: [],
    dislikes: []
  };

  componentDidMount() {
    const recipeId = this.props.match.params.recipeId;
    const recipeName = this.props.location.state.recipeName;
    const allergens = this.props.location.state.allergens;
    const dislikes = this.props.location.state.dislikes;

    console.log("Allergens: " + JSON.stringify(allergens, null, 2));
    console.log("Dislikes: " + JSON.stringify(dislikes, null, 2));

    fetch(`${CULINARY_API.URL}/substitutes?recipeId=${recipeId}`, {
      headers: {
        Authorization: `Basic ${btoa(
          `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
        )}`,
        Accept: "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        console.log(JSON.stringify(json.data, null, 2));
        let replacements = json.data.map(replacement => {
          if (
            (allergens &&
              allergens.length > 0 &&
              allergens.find(
                it => it.ingredientId === replacement.ingredientId
              )) ||
            (dislikes &&
              dislikes.length > 0 &&
              dislikes.find(it => it.id === replacement.ingredientId))
          ) {
            replacement.allergy = true;
            return replacement;
          } else {
            replacement.allergy = false;
            return replacement;
          }
        });

        this.setState({
          replacements: replacements,
          recipeName: recipeName,
          loading: false,
          allergens: allergens,
          dislikes: dislikes
        });
      })
      .catch(error => {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
  }

  onIgredientClicked(index) {
    this.setState({
      selectedIngredient: index
    });
  }

  onSubstituteClicked(
    ingredientId,
    ingredientName,
    substituteId,
    substituteName
  ) {
    let selectedSubstitutesCopy = this.state.selectedSubstitutes;
    let existing = false;
    selectedSubstitutesCopy.forEach(entry => {
      if (ingredientId === entry.ingredient.id) {
        existing = true;
        entry.substitute.id = substituteId;
        entry.substitute.name = substituteName;
      }
    });
    if (!existing) {
      selectedSubstitutesCopy.push({
        ingredient: {
          id: ingredientId,
          name: ingredientName
        },
        substitute: {
          id: substituteId,
          name: substituteName
        }
      });
    }
    this.setState({
      selectedSubstitutes: selectedSubstitutesCopy
    });
  }

  onRemoveSubstituteClicked(
    index
  ) {
    let selectedSubstitutesCopy = this.state.selectedSubstitutes;
    selectedSubstitutesCopy.splice(index, 1);
    this.setState({
      selectedSubstitutes: selectedSubstitutesCopy
    });
  }

  onChange = event => {
    const recipeName = event.target.value;
    this.setState({ recipeName: recipeName });
  };

  render() {
    const {
      replacements,
      recipeName,
      selectedIngredient,
      selectedSubstitutes,
      allergens,
      dislikes,
      loading
    } = this.state;

    let substitutes = [];
    if (selectedIngredient !== null) {
      substitutes = replacements[selectedIngredient].substitutes.filter(
        substitute => {
          for (const allergen of allergens) {
            if (substitute.allergies.find(it => it === allergen.allergy)) {
              return false;
            }
          }

          if (dislikes.find(it => it.id === substitute.id)) {
            return false;
          }

          return true;
        }
      );
    }

    return (
      <div>
        <PersonalizeRecipeSteps
          loading={loading}
          current={1}
          customizeStatus="progress"
        />
        {!loading && (
          <div>
            <Input
              value={recipeName}
              onChange={this.onChange}
              size="large"
              style={{ marginBottom: 10 }}
            />
            <Row gutter={16} style={{ paddingBottom: 16 }}>
              <Col span={6}>
                <List
                  header={
                    <div>
                      <b>Ingredients</b>
                    </div>
                  }
                  bordered
                  dataSource={replacements}
                  renderItem={(ingredient, index) => (
                    <List.Item>
                      <Button
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          textAlign: "start",
                          padding: "initial"
                        }}
                        onClick={() => this.onIgredientClicked(index)}
                      >
                        {ingredient.allergy ? (
                          <div>
                            {ingredient.ingredientName}
                            <Icon
                              type="exclamation-circle"
                              style={{ color: "red", marginLeft: 10 }}
                            />
                          </div>
                        ) : (
                            <div>{ingredient.ingredientName}</div>
                          )}
                      </Button>
                    </List.Item>
                  )}
                />
              </Col>
              {selectedIngredient !== null ? (
                <Col span={6}>
                  <List
                    header={
                      <div>
                        <b>
                          Substitutes of{" "}
                          {replacements[selectedIngredient].ingredientName}
                        </b>
                      </div>
                    }
                    bordered
                    dataSource={substitutes}
                    renderItem={sub => (
                      <List.Item>
                        <Button
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            textAlign: "start",
                            padding: "initial"
                          }}
                          onClick={() =>
                            this.onSubstituteClicked(
                              replacements[selectedIngredient].ingredientId,
                              replacements[selectedIngredient].ingredientName,
                              sub.id,
                              sub.name
                            )
                          }
                        >
                          {`${sub.name}`}
                        </Button>
                      </List.Item>
                    )}
                  />
                </Col>
              ) : (
                  <div />
                )}

              {selectedSubstitutes.length > 0 ? (
                <Col span={12}>
                  <List
                    header={
                      <div>
                        <b>Selected substitutes</b>
                      </div>
                    }
                    bordered
                    dataSource={selectedSubstitutes}
                    renderItem={(sub, index) => (
                      <List.Item>
                        <Button
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            textAlign: "start",
                            padding: "initial"
                          }}
                        >
                          {`${sub.ingredient.name} => ${sub.substitute.name}`}
                        </Button>
                        <Icon
                          type="delete"
                          style={{cursor: 'pointer'}}
                          onClick={() =>
                            this.onRemoveSubstituteClicked(
                              index
                            )
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              ) : (
                  <div />
                )}
            </Row>
            <div style={{ textAlign: "left", paddingTop: 10 }}>
              <Link
                to={{
                  pathname: `/recipes/${this.props.match.params.recipeId}`,
                  state: { modified: false }
                }}
              >
                <Button type="default">Cancel</Button>
              </Link>
              <Link
                to={{
                  pathname: `/recipes/${this.props.match.params.recipeId}`,
                  state: {
                    modified: true,
                    recipeName: recipeName,
                    replacements: selectedSubstitutes
                  }
                }}
              >
                <Button type="primary" style={{ marginLeft: 16 }}>
                  <Icon type="check" />
                  Confirm
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CustomizeRecipe;

const API_URL = "https://nluapi.mykie.rocks";

class NluApi {
  // *** NLU API ***

  async doTranslateRecipe(recipeDetails) {
    console.log(recipeDetails);
    const ingredientsString = recipeDetails.ingredient_list
      .map(it => `${it.amount} || ${it.ingredient}`)
      .reduce((accumulator, currentValue) =>
        accumulator.concat(" /// ", currentValue)
      );
    const stepsString = recipeDetails.method_text.reduce(
      (accumulator, currentValue) => accumulator.concat(" /// ", currentValue)
    );

    // translate recipe ingredients using NLU API
    const ingredientsEng = await fetch(API_URL + "/translate_google", {
      method: "post",
      body: JSON.stringify({
        text: ingredientsString,
        language_pair: "de2en"
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json[0].translated.split(" /// ").map(it => {
          const ingredientArray = it.split("|| ");
          if (ingredientArray.length > 1) {
            return {
              amount: ingredientArray[0],
              ingredient: ingredientArray[1]
            };
          } else {
            return {
              amount: "",
              ingredient: ingredientArray[0]
            };
          }
        });
      });

    // Translate recipe steps using NLU API
    const stepsEng = await fetch(API_URL + "/translate_google", {
      method: "post",
      body: JSON.stringify({
        text: stepsString,
        language_pair: "de2en"
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json[0].translated.split(" /// ");
      });

    // Translate recipe title
    const recipeTitleEng = await fetch(API_URL + "/translate_google", {
      method: "post",
      body: JSON.stringify({
        text: recipeDetails.title_string,
        language_pair: "de2en"
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json[0].translated;
      });

    // Prepare recipeDetails response
    recipeDetails.title_string = recipeTitleEng;
    recipeDetails.ingredient_list = ingredientsEng;
    recipeDetails.method_text = stepsEng;
    return recipeDetails;
  }

  async doFetchRecipeDetails(url) {
    let recipeDetails;
    if (url.includes("chefkoch")) {
      recipeDetails = await fetch(API_URL + "/crawlchefkoch", {
        method: "post",
        body: JSON.stringify({
          url: url
        })
      });
    } else {
      recipeDetails = await fetch(API_URL + "/crawlks", {
        method: "post",
        body: JSON.stringify({
          url: url
        })
      });
    }

    return await recipeDetails.json();
  }

  async doFetchCachedRecipeDetails(url) {
    const recipeDetails = await fetch(API_URL + "/crawl_cached", {
      method: "post",
      body: JSON.stringify({
        url: url
      })
    });

    return await recipeDetails.json();
  }

  async doTokenizeSentence(text) {
    const tokenizedSentence = await fetch(API_URL + "/toksentence", {
      method: "post",
      body: JSON.stringify({
        text: text
      })
    });

    return await tokenizedSentence.json();
  }

  async doPronounResolution(text) {
    const resolution = await fetch(API_URL + "/pronoun_resolution", {
      method: "POST",
      body: JSON.stringify([{
        text: text
      }])
    });

    return await resolution.json();
  }

  async doPredictAction(text) {
    const tokenizedSentence = await fetch(API_URL + "/predictaction", {
      method: "post",
      body: JSON.stringify({
        text: text
      })
    });

    return await tokenizedSentence.json();
  }

  async doChunkPhrase(sentences) {
    const tokenizedSentence = await fetch(API_URL + "/phrasechunker", {
      method: "post",
      body: JSON.stringify({
        sents: sentences
      })
    });

    return await tokenizedSentence.json();
  }

  async doPredictEntityIngredient(text) {
    const tokenizedSentence = await fetch(
      API_URL + "/predictentity_ingredient",
      {
        method: "post",
        body: JSON.stringify({
          text: text
        })
      }
    );

    return await tokenizedSentence.json();
  }

  async doPredictEntityInstruction(text) {
    const tokenizedSentence = await fetch(
      API_URL + "/predictentity_instruction",
      {
        method: "post",
        body: JSON.stringify({
          text: text
        })
      }
    );

    return await tokenizedSentence.json();
  }

  async doApplianceSettingExtranction(text) {
    const tokenizedSentence = await fetch(
      API_URL + "/rac_appliance_settings_simple",
      {
        method: "post",
        body: JSON.stringify({
          text: text
        })
      }
    );

    return await tokenizedSentence.json();
  }
}

export default NluApi;


const API_URL = "http://ec2-52-57-14-122.eu-central-1.compute.amazonaws.com/api";

class KitchenQAApi {

  // *** KITCHEN QA API ***

  async doFetchResponse(question) {

    const response = await fetch(API_URL + '/question', {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        event: {
          type: "web_app",
          text: question
        }
      })
    });

    return await response.json();
  }

}

export default KitchenQAApi;

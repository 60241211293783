import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Row, Col, Modal } from "antd";
import { withFirebase } from "../Firebase";

import { CULINARY_API } from "../../constants/apis";
import BuildRecipeSteps from "../BuildRecipeSteps";

const ALLERGIES = {
  LACTOSE: "Lactose",
  GLUTEN: "Gluten",
  WHEAT: "Wheat",
  NUTS: "Nuts"
};

const DIETARY_TAGS = {
  GLUTEN_FREE: "Gluten-free",
  LACTOSE_FREE: "Lactose-free",
  WITHOUT_WHEAT: "Without wheat",
  WITHOUT_NUTS: "Without nuts"
};

class RecipeVariantsPage extends React.Component {
  state = {
    dishModel: null,
    loading: true,
    maxBlocksCount: 0,
    selectedBB: [],
    userAllergies: [],
    modalVisible: false
  };

  componentDidMount() {
    const dishModelId = this.props.match.params.dishModelId;
    fetch(`${CULINARY_API.URL}/dish-models/${dishModelId}`, {
      headers: {
        Authorization: `Basic ${btoa(
          `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
        )}`,
        Accept: "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let dishModel = data.data;
        // ***** TODO: filter not corrected variants **** //
        dishModel.variants = dishModel.variants.filter(
          it =>
            it.id !== "5c3c688576be975b6cacd91b" &&
            it.id !== "5c3c688776be975b6cacd920"
        );
        let maxBlocksCount = 0;
        for (const variant of dishModel.variants) {
          if (variant.buildingBlocks.length > maxBlocksCount) {
            maxBlocksCount = variant.buildingBlocks.length;
          }
        }
        this.setState(
          {
            dishModel: dishModel,
            maxBlocksCount: maxBlocksCount
          },
          () => {
            this.getUserAllergies();
          }
        );
      })
      .catch(error => {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
  }

  getUserAllergies() {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db
      .ref(`/users/${userId}`)
      .once("value")
      .then(snapshot => {
        const userObj = snapshot.val();
        const allergies = userObj.allergies
          ? Object.keys(userObj.allergies).map(key => {
              return userObj.allergies[key];
            })
          : [];
        this.setState(
          {
            userAllergies: allergies.map(allergyObj => allergyObj.name),
            loading: false
          },
          () => {
            this.preselectVariantUser();
          }
        );
      });
  }

  selectBuildingBlock(variantId, blockName) {
    let buildingBlocks = this.state.selectedBB;
    // filter element with the same block name
    buildingBlocks = buildingBlocks.filter(it => it.blockName !== blockName);
    // create new BB
    const newBuildingBlock = {
      id: `${variantId}:${blockName}`,
      variantId: variantId,
      blockName: blockName
    };
    buildingBlocks.push(newBuildingBlock);
    this.setState({ selectedBB: buildingBlocks });
  }

  getDietaryInfo(allergies) {
    let dietaryTags = [];
    if (!allergies.includes(ALLERGIES.GLUTEN))
      dietaryTags.push(DIETARY_TAGS.GLUTEN_FREE);
    if (!allergies.includes(ALLERGIES.LACTOSE))
      dietaryTags.push(DIETARY_TAGS.LACTOSE_FREE);
    if (!allergies.includes(ALLERGIES.WHEAT))
      dietaryTags.push(DIETARY_TAGS.WITHOUT_WHEAT);
    if (!allergies.includes(ALLERGIES.NUTS))
      dietaryTags.push(DIETARY_TAGS.WITHOUT_NUTS);
    return dietaryTags.join(", ");
  }

  preselectVariantUser() {
    const userAllergies = this.state.userAllergies;
    console.log(userAllergies);
    let buildingBlocks = [];
    for (const variant of this.state.dishModel.variants) {
      for (const bb of variant.buildingBlocks) {
        let includesAllergy = false;
        for (const userAllergy of userAllergies) {
          if (bb.allergies.includes(userAllergy)) includesAllergy = true;
        }
        if (!includesAllergy) {
          console.log(`Adding BB ${bb.name} from variant: ${variant.name}`);
          // this.selectBuildingBlock(variant.id, bb.name);
          // filter element with the same block name
          buildingBlocks = buildingBlocks.filter(
            it => it.blockName !== bb.name
          );
          // create new BB
          const newBuildingBlock = {
            id: `${variant.id}:${bb.name}`,
            variantId: variant.id,
            blockName: bb.name
          };
          buildingBlocks.push(newBuildingBlock);
        }
      }
    }
    this.setState({ selectedBB: buildingBlocks, modalVisible: true });
  }

  preselectVariantLucky() {
    let selectedBB = [];
    const bbList = [];

    for (const variant of this.state.dishModel.variants) {
      for (let bb of variant.buildingBlocks) {
        bb.variantId = variant.id;
        bbList.push(bb);
      }
    }

    const bbNames = this.state.dishModel.variants[0].buildingBlocks.map(
      bb => bb.name
    );

    for (const bbName of bbNames) {
      const bbWithSameName = bbList.filter(bb => bb.name === bbName);
      const randomBB =
        bbWithSameName[Math.floor(Math.random() * bbWithSameName.length)];
      const randomBBFull = {
        id: `${randomBB.variantId}:${randomBB.name}`,
        variantId: randomBB.variantId,
        blockName: randomBB.name
      };
      selectedBB.push(randomBBFull);
    }
    this.setState({ selectedBB: selectedBB });
  }

  handleModalOk = e => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      dishModel,
      selectedBB,
      loading,
      modalVisible,
      maxBlocksCount
    } = this.state;
    let colSpan = 7;

    switch (maxBlocksCount) {
      case 4:
        colSpan = 5;
        break;
      case 5:
        colSpan = 4;
        break;
      case 6:
        colSpan = 3;
        break;
      default:
        colSpan = 7;
        break;
    }

    const blockColumns = dishModel
      ? dishModel.variants[0].buildingBlocks.map((block, index, blocks) => {
          return (
            <Col span={colSpan} key={index}>
              <h2 style={{ textAlign: "center" }}>{`Block ${index + 1}: ${
                block.name
              }`}</h2>
            </Col>
          );
        })
      : null;

    const bbRows = dishModel
      ? dishModel.variants.map(variant => {
          const bbCols = variant.buildingBlocks.map((bb, index) => {
            const ingredients = bb.roles.map(role => {
              return `${role.ingredients.map(it => it.name).join(", ")}`;
            });
            return (
              <Col span={colSpan} key={index}>
                <Button
                  block={true}
                  key={`${variant.id}:${bb.name}`}
                  type={
                    selectedBB.some(it => it.id === `${variant.id}:${bb.name}`)
                      ? "primary"
                      : "default"
                  }
                  style={{
                    minHeight: 90,
                    borderRadius: "15px",
                    whiteSpace: "normal"
                  }}
                  onClick={() => this.selectBuildingBlock(variant.id, bb.name)}
                >
                  {`Ingredients: ${ingredients.join(", ")}`}
                  {maxBlocksCount < 5 &&
                    `Dietary info: ${this.getDietaryInfo(bb.allergies)}`}
                </Button>
              </Col>
            );
          });
          return (
            <Row key={variant.id} gutter={40}>
              <Col span={3}>
                <h3
                  style={{
                    minHeight: 90,
                    width: "100%",
                    textAlign: "center",
                    paddingTop: 20
                  }}
                >
                  {variant.name}
                </h3>
              </Col>
              {bbCols}
            </Row>
          );
        })
      : null;

    const buttonStyle = { width: 100, marginRight: 20, marginTop: 10 };
    const nextButton = (
      <Button
        type="primary"
        style={buttonStyle}
        disabled={selectedBB.length >= 3 ? false : true}
      >
        Next
        <Icon type="right" />
      </Button>
    );

    return (
      <div>
        <Modal
          title="Dietary info alert"
          visible={modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalOk}
          icon="success"
        >
          <p>
            System preselected recipe variant according to your dietary
            preferences.
          </p>
          <p>Selected components don't contain following allergens:</p>
          <ul>
            {this.state.userAllergies.map((allergen, index) => {
              return <li key={index}>{allergen}</li>;
            })}
          </ul>
        </Modal>
        <BuildRecipeSteps
          loading={loading}
          current={1}
          customizeStatus="wait"
        />
        {!loading && (
          <div>
            <h1>Variants of {dishModel ? dishModel.name : ""}</h1>
            <Row style={{ marginTop: 20 }}>
              <Col span={3} />
              {blockColumns}
            </Row>
            {bbRows}
            <Button
              type="primary"
              size="large"
              onClick={() => this.preselectVariantLucky()}
              style={{ marginTop: 10 }}
            >
              <Icon type="star" />
              I'm Feeling Lucky
            </Button>
            <div style={{ textAlign: "left", paddingTop: 10 }}>
              <Link
                to={{ pathname: `/build-recipe`, state: { modified: false } }}
              >
                <Button type="default" style={buttonStyle}>
                  <Icon type="left" />
                  Back
                </Button>
              </Link>
              <Link
                to={{
                  pathname: `/mixed-recipe`,
                  state: {
                    modified: false,
                    dishModelId: dishModel.id,
                    selectedBB: selectedBB
                  }
                }}
              >
                {nextButton}
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(RecipeVariantsPage);

import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { message, Card, Col, Row, Input, Tabs } from "antd";

import { CULINARY_API } from "../../constants/apis";
import dishImages from "../../mocks/dishImagesAll";
import PersonalizeRecipeSteps from "../PersonalizeRecipeSteps";

const { Meta } = Card;
const { TabPane } = Tabs;
const Search = Input.Search;

class RecipeSearchPage extends React.Component {
  state = {
    recipes: [],
    searchResults: [],
    loading: true,
    userAllergies: []
  };

  componentDidMount() {
    // fetch recipes from culinary API
    fetch(`${CULINARY_API.URL}/recipes/`, {
      headers: {
        Authorization: `Basic ${btoa(
          `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
        )}`,
        Accept: "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let recipes = data.data.map(recipe => {
          recipe.image = dishImages.data.find(
            it => it.id === recipe.dishModel.id
          );
          return recipe;
        });
        recipes = recipes.filter(it => it.image);
        console.log(JSON.stringify(recipes, null, 2));
        this.setState(
          {
            recipes: recipes,
            searchResults: recipes
          },
          () => {
            this.getUserAllergies();
          }
        );
      })
      .catch(error => {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
        this.setState({ loading: false });
        message.error("There was an error connecting to Culinary API");
      });
  }

  getUserAllergies() {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    firebase.db
      .ref(`/users/${userId}`)
      .once("value")
      .then(snapshot => {
        const userObj = snapshot.val();
        const allergies = userObj.allergies
          ? Object.keys(userObj.allergies).map(key => {
              return userObj.allergies[key];
            })
          : [];
        this.setState({
          userAllergies: allergies.map(allergyObj => allergyObj.name),
          loading: false
        });
      });
  }

  onTabChanged = key => {
    if (key === "1") {
      const allRecipes = this.state.recipes;
      this.setState({ searchResults: allRecipes });
    } else if (key === "2") {
      // prepare reccomendation list
      console.log(key);
      const searchResults = this.state.recipes.filter(recipe => {
        let result = true;
        for (const allergy of this.state.userAllergies) {
          if (recipe.allergies.includes(`Allergie_${allergy}`)) {
            result = false;
            break;
          }
        }
        return result;
      });
      this.setState({ searchResults: searchResults });
    }
  };

  handleSearch(value) {
    const searchResults = this.state.recipes.filter(recipe =>
      recipe.name.toLowerCase().includes(value)
    );
    this.setState({ searchResults: searchResults });
  }

  render() {
    const { searchResults, loading } = this.state;
    let cols = [];
    let rows = [];
    cols = searchResults.map(recipe => {
      return (
        <Col span={8} key={recipe.id}>
          <Link
            to={{
              pathname: `/recipes/${recipe.id}`,
              state: { modified: false, fromSearch: true }
            }}
          >
            <Card
              hoverable
              style={{ marginBottom: 16 }}
              cover={
                <img
                  alt={recipe.image ? recipe.image.alt : ""}
                  src={recipe.image ? recipe.image.url : ""}
                />
              }
            >
              <Meta title={recipe.name} />
            </Card>
          </Link>
        </Col>
      );
    });
    let colsBuffer = [];
    const colsCount = 3;
    rows = cols.map((col, index, cols) => {
      if ((index + 1) % colsCount === 0) {
        colsBuffer.push(col);
        const row = (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
        colsBuffer = [];
        return row;
      } else if (cols.length === index + 1) {
        colsBuffer.push(col);
        return (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
      } else {
        colsBuffer.push(col);
      }
    });

    return (
      <div>
        <PersonalizeRecipeSteps
          loading={loading}
          current={0}
          customizeStatus="wait"
        />
        {!loading && (
          <div>
            <Tabs
              size="large"
              defaultActiveKey="1"
              style={{ textAlign: "center" }}
              onTabClick={this.onTabChanged}
            >
              <TabPane tab="Search" key="1">
                <Row>
                  <Search
                    placeholder="Search by recipe title"
                    onSearch={value => this.handleSearch(value)}
                    enterButton
                    size="large"
                    style={{ marginBottom: 20 }}
                  />
                </Row>
              </TabPane>
              <TabPane tab="Recommendations" key="2">
                <Row>
                  <h1>Just for You</h1>
                </Row>
              </TabPane>
            </Tabs>
            <div>{rows.length > 0 && rows}</div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(RecipeSearchPage);

import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";

import { Layout, Menu, Dropdown, Icon, Avatar } from "antd";

import * as ROUTES from "../../constants/routes";
import SignOutButton from "../SignOut";

const { Header } = Layout;

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to={ROUTES.PROFILE}>Profile</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <SignOutButton />
    </Menu.Item>
  </Menu>
);

class AppHeader extends React.Component {
  state = { username: "Anonymous" };

  componentDidMount() {
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;

    firebase.db
      .ref("/users/" + userId)
      .once("value")
      .then(snapshot => {
        const username =
          (snapshot.val() && snapshot.val().username) || "Anonymous";
        this.setState({ username: username });
      });
  }

  render() {
    let headerContent;
    const { username } = this.state;

    headerContent = (
      <div>
        <Avatar
          style={{ margin: 10 }}
          src="https://www.doggyandthecity.com/wp-content/uploads/2014/05/meanswear-dog.jpg"
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <a
            className="ant-dropdown-link"
            href="profile"
            style={{ color: "grey" }}
          >
            {username}
            <Icon type="down" style={{ margin: 10 }} />
          </a>
        </Dropdown>
      </div>
    );
    return (
      <Header style={{ background: "white", padding: 0 }}>
        <div style={{ textAlign: "right", marginRight: 20 }}>
          {headerContent}
        </div>
      </Header>
    );
  }
}

export default withFirebase(AppHeader);

import React from "react";

const KitchenQAApiContext = React.createContext(null);

export const withKitchenQAApi = Component => props => (
  <KitchenQAApiContext.Consumer>
    {kitchenQAApi => <Component {...props} kitchenQAApi={kitchenQAApi} />}
  </KitchenQAApiContext.Consumer>
);

export default KitchenQAApiContext;

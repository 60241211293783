import React from "react";
import { Link } from "react-router-dom";
import { message, Card, Col, Row, Input } from "antd";

import { CULINARY_API } from "../../constants/apis";
import dishImages from "../../mocks/dishImagesAll";
import BuildRecipeSteps from "../BuildRecipeSteps";

const { Meta } = Card;
const Search = Input.Search;

class BuildRecipePage extends React.Component {
  state = {
    dishModels: [],
    searchResults: [],
    loading: true
  };

  componentDidMount() {
    // fetch dish models from culinary API
    fetch(`${CULINARY_API.URL}/dish-models/`, {
      headers: {
        Authorization: `Basic ${btoa(
          `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
        )}`,
        Accept: "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let dishModels = data.data.map(model => {
          model.image = dishImages.data.find(it => it.id === model.id);
          return model;
        });
        dishModels = dishModels.filter(it => it.image);
        this.setState({
          dishModels: dishModels,
          searchResults: dishModels,
          loading: false
        });
      })
      .catch(error => {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
        this.setState({ loading: false });
        message.error("There was an error connecting to Culinary API");
      });
  }

  handleSearch(value) {
    const searchResults = this.state.dishModels.filter(dishModel =>
      dishModel.name.toLowerCase().includes(value)
    );
    this.setState({ searchResults: searchResults });
  }

  render() {
    const { searchResults, loading } = this.state;
    let cols = [];
    let rows = [];
    cols = searchResults.map(model => {
      return (
        <Col span={8} key={model.id}>
          <Link to={`/variants/${model.id}`}>
            <Card
              hoverable
              style={{ marginBottom: 16 }}
              cover={
                <img
                  alt={model.image ? model.image.alt : ""}
                  src={model.image ? model.image.url : ""}
                />
              }
            >
              <Meta title={model.image ? model.image.name : model.name} />
            </Card>
          </Link>
        </Col>
      );
    });
    let colsBuffer = [];
    const colsCount = 3;
    rows = cols.map((col, index, cols) => {
      if ((index + 1) % colsCount === 0) {
        colsBuffer.push(col);
        const row = (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
        colsBuffer = [];
        return row;
      } else if (cols.length === index + 1) {
        colsBuffer.push(col);
        return (
          <Row gutter={16} key={col.key}>
            {colsBuffer}
          </Row>
        );
      } else {
        colsBuffer.push(col);
      }
    });
    return (
      <div>
        <BuildRecipeSteps
          loading={loading}
          current={0}
          customizeStatus="wait"
        />
        <Search
          placeholder="input search text"
          onSearch={value => this.handleSearch(value)}
          enterButton
          size="large"
          style={{ marginBottom: 20 }}
        />
        {!loading && <div>{rows.length > 0 && rows}</div>}
      </div>
    );
  }
}

export default BuildRecipePage;

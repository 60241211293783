import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { List, Row, Col, Button, Avatar, Icon, Input, message } from "antd";

import BuildRecipeSteps from "../BuildRecipeSteps";
import * as ROUTES from "../../constants/routes";
import { NLG_API, RECIPE_MIXER_API } from "../../constants/apis";

class MixedRecipePage extends React.Component {
  state = {
    recipe: null,
    dishModelId: null,
    steps: [],
    loading: true,
    fromSearch: false,
    allergens: [],
    dislikes: [],
    error: false
  };

  componentDidMount() {
    // selected dish model
    const dishModelId = this.props.location.state.dishModelId;
    this.setState({ dishModelId: dishModelId });
    // array containing selected building blocks from recipe variants screen
    const selectedBB = this.props.location.state.selectedBB;
    const blocks = selectedBB.map(it => {
      return {
        block_name: it.blockName,
        variant_id: it.variantId
      };
    });

    const requestBody = {
      dishmodel_id: dishModelId,
      blocks: blocks
    };

    console.log("Request body: " + JSON.stringify(requestBody, null, 2));

    // Generated recipe from selected building blocks using Recipe Mixer API
    fetch(`${RECIPE_MIXER_API.URL}/recipe_mixer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(json => {
        this.setState({ recipe: json.data });
        const nlgRequestBody = this.prepareNlgRequest(json.data.steps);

        // fetch data from NLG API
        fetch(NLG_API.URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(
              `${NLG_API.USER}:${NLG_API.PASSWORD}`
            )}`
          },
          body: JSON.stringify(nlgRequestBody)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then(data => {
            console.log("Steps from NLG: " + JSON.stringify(data, null, 2));
            const steps = data.map((step, index) => {
              step.modified = this.state.recipe.steps[index].modified;
              return step;
            });
            this.setState({ steps: steps, loading: false });
          })
          .catch(error => {
            console.error(error);
            message.error("Failed to fetch information from NLG API");
            this.setState({ loading: false, error: true });
          });
      })
      .catch(error => {
        console.error(error);
        message.error("Failed to fetch information from Recipe-Mixing API. Please try different dish variant.");
        this.setState({ loading: false, error: true });
      });
  }

  prepareNlgRequest(steps) {
    // prepare NLG request body
    const nlgSteps = steps.map(step => {
      // TODO!
      const containerTemplates = [
        "bowl",
        "pot",
        "pan",
        "container",
        "plate",
        "surface"
      ];
      let tools = [];
      let containers = [];
      if (step.technique && step.technique.tools) {
        for (const kitchenware of step.technique.tools) {
          let added = false;
          for (const it of containerTemplates) {
            if (kitchenware.toLowerCase().includes(it)) {
              containers.push({ name: kitchenware.toLowerCase() });
              added = true;
              break;
            }
          }
          if (!added) {
            tools.push({ name: kitchenware.toLowerCase() });
          }
        }
      }

      const prevResults = step.prevResult.map(it => {
        return { name: it };
      });

      for (const prevResult of prevResults) {
        step.ingredients.unshift(prevResult);
      }
      // TODO - needs refactor!
      return {
        "home appliance": step.technique.name === "Preheat" ? "oven" : "",
        technique:
          step.technique && step.technique.name
            ? step.technique.name.toLowerCase()
            : "",
        prestep: step.preStep ? step.preStep.name.toLowerCase() : "",
        utensils: containers,
        action_during_technique: {
          action_technique:
            step.technique && step.technique.actionDuringTechnique
              ? step.technique.actionDuringTechnique.toLowerCase()
              : "",
          tools: tools,
          interval:
            step.technique && step.technique.intervalDuringTechnique
              ? step.technique.intervalDuringTechnique.toLowerCase()
              : ""
        },
        ingredients: step.ingredients.map(ingredient => {
          return {
            name: ingredient.name.toLowerCase(),
            quantity: ingredient.quantity ? `${ingredient.quantity}` : "",
            unit: ingredient.unit ? ingredient.unit.toLowerCase() : ""
          };
        }),
        parameters: {
          time: {
            value: step.technique ? `${step.technique.duration}` : "1",
            unit: step.technique ? step.technique.unit.toLowerCase() : "min"
          },
          temperature: {
            value:
              step.technique.name === "Preheat"
                ? step.technique.setting.substring(
                    0,
                    step.technique.setting.indexOf("℃")
                  )
                : null,
            unit: step.technique.name === "Preheat" ? "℃" : null
          }
        },
        conditions: step.constraint
          ? [{ value: step.constraint.toLowerCase() }]
          : []
      };
    });
    return { actions: nlgSteps };
  }

  handleRightButtonClick = () => {
    let newRecipe = this.state.recipe;
    newRecipe.name = this.state.recipe.name;
    newRecipe.steps = this.state.steps;
    const { firebase } = this.props;
    const userId = firebase.auth.currentUser.uid;
    const newRecipeKey = firebase.db
      .ref(`/users/${userId}`)
      .child("recipes")
      .push().key;
    let updates = {};

    // *** quick fix TODO
    newRecipe.steps.map(step => {
      if (typeof step.modified === "undefined") {
        step.modified = false;
      }
      return step;
    });
    // ***

    updates[`/recipes/${newRecipeKey}`] = newRecipe;
    firebase.db.ref(`/users/${userId}`).update(updates);
  };

  addRecipeName = e => {
    console.log(e.target.value);
    let recipe = this.state.recipe;
    recipe.name = e.target.value;
    this.setState({ recipe: recipe });
  };

  render() {
    const {
      recipe,
      dishModelId,
      steps,
      loading,
      error,
      allergens,
      dislikes
    } = this.state;

    let actionButtons = {
      leftButtonText: "Back",
      leftButtonPath: `/variants/${dishModelId}`,
      leftButtonIcon: "left",
      rightButtonText: "Save recipe",
      rightButtonPath: ROUTES.MY_RECIPES,
      rightButtonIcon: "heart"
    };

    return (
      <div>
        <BuildRecipeSteps loading={loading} current={2} />
        {!loading && !error && (
          <div>
            {recipe.name ? (
              <h1>{`${recipe.name}`}</h1>
            ) : (
              <Input
                placeholder="Enter name for new recipe"
                size="large"
                onPressEnter={this.addRecipeName}
                style={{ marginBottom: 16 }}
              />
            )}

            <Row gutter={16} style={{ paddingBottom: 16 }}>
              <Col span={8}>
                <List
                  header={<div>Ingredients</div>}
                  bordered
                  dataSource={recipe ? recipe.ingredients : []}
                  renderItem={ingredient => {
                    const listModified = (
                      <List.Item style={{ background: "#91d5ff" }}>
                        {`${ingredient.quantity} ${ingredient.unit} ${ingredient.name}`}
                      </List.Item>
                    );
                    const listNormal = (
                      <List.Item>
                        <div>
                          {`${ingredient.quantity} ${ingredient.unit} ${ingredient.name}`}
                          {(allergens.find(
                            it => it.ingredientId === ingredient.id
                          ) ||
                            dislikes.find(it => it.id === ingredient.id)) && (
                            <Icon
                              type="exclamation-circle"
                              style={{ color: "red", marginLeft: 10 }}
                            />
                          )}
                        </div>
                      </List.Item>
                    );
                    return ingredient.modified ? listModified : listNormal;
                  }}
                />
              </Col>
              <Col span={16}>
                <List
                  header={<div>Steps</div>}
                  bordered
                  dataSource={steps ? steps : []}
                  renderItem={step => {
                    const listModified = (
                      <List.Item style={{ background: "#91d5ff" }}>
                        <List.Item.Meta
                          avatar={<Avatar>{`${step.id + 1}`}</Avatar>}
                          title={`Step ${step.id + 1}`}
                          description={step.step}
                        />
                      </List.Item>
                    );
                    const listNormal = (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar>{`${step.id + 1}`}</Avatar>}
                          title={`Step ${step.id + 1}`}
                          description={step.step}
                        />
                      </List.Item>
                    );
                    return step.modified ? listModified : listNormal;
                  }}
                />
              </Col>
            </Row>
            {!this.props.location.state.recipe && (
              <div style={{ textAlign: "left", paddingTop: 10 }}>
                <Link to={actionButtons.leftButtonPath}>
                  <Button type="default">
                    <Icon type={actionButtons.leftButtonIcon} />
                    {actionButtons.leftButtonText}
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: actionButtons.rightButtonPath,
                    state: actionButtons.rightButtonState
                  }}
                >
                  <Button
                    type="primary"
                    onClick={this.handleRightButtonClick}
                    style={{ marginLeft: 16 }}
                  >
                    <Icon type={actionButtons.rightButtonIcon} />
                    {actionButtons.rightButtonText}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        )}
        {error && (
          <div style={{ textAlign: "left", paddingTop: 10 }}>
            <Link to={actionButtons.leftButtonPath}>
              <Button type="default">
                <Icon type={actionButtons.leftButtonIcon} />
                {"Back to Variants screen"}
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(MixedRecipePage);

export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const BUILD_RECIPE = "/build-recipe";
export const SEARCH_RECIPE = "/search-recipe";
export const HOME = "/";
export const PROFILE = "/profile";
export const MY_RECIPES = "/my-recipes";
export const PASSWORD_FORGET = "/pw-forget";
export const NLU_DEMO = "/nlu-demo";
export const KITCHEN_QA = "/kitchenqa";
export const RECIPE_VARIANTS = "/variants/:dishModelId";
export const RECIPE = "/recipes/:recipeId";
export const CUSTOMIZE_RECIPE = "/customize-recipe/:recipeId";
export const MIXED_RECIPE = "/mixed-recipe";
import React, { Component } from "react";
import { withNluApi } from "../NluApi";
import {
  Alert,
  Card,
  Button,
  Input,
  Form,
  List,
  Row,
  Col,
  Timeline,
  Checkbox,
  Spin,
  message,
  Icon,
  AutoComplete
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { TokenAnnotator } from "react-text-annotate";

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

const rowStyle = {
  padding: "5px"
};

const cardStyle = {
  height: "100%"
};

const TAG_COLORS = {
  SENTENCE: "lightblue",
  ACTION: "greenyellow",
  CONJUNCTION: "coral",
  CONDITION: "plum",
  NN: "lightblue",
  NNP: "lightblue",
  NNS: "lightblue",
  IN: "greenyellow",
  DT: "coral",
  CD: "plum",
  VBN: "cyan",
  VBD: "salmon",
  JJ: "burlywood",
  PRP: "cornsilk",
  COMBINED: "turquoise",
  INGREDIENT: "plum",
  UNIT: "cyan",
  VALUE: "salmon",
  APPLIANCE: "burlywood",
  UTENSIL: "cornsilk",
  PHRASE: "plum",
  NOMINAL: "plum",
  PRONOMINAL: "lightblue"
};

const TAGS = {
  SENTENCE: "SENTENCE",
  NN: "NOUN",
  NNP: "NOUN",
  NNS: "NOUN",
  IN: "PREPOSITION",
  DT: "ARTICLE",
  CD: "CARD. NUMBERS",
  VBN: "V.P. PARTICIPLES",
  VBD: "V.P. TENSE",
  JJ: "ADJECTIVE",
  PRP: "PRONOUN",
  INGREDIENT: "INGREDIENT",
  UNIT: "UNIT",
  VALUE: "VALUE",
  APPLIANCE: "APPLIANCE",
  UTENSIL: "UTENSIL",
  PHRASE: "PHRASE",
  NOMINAL: "NOMINAL",
  PRONOMINAL: "PRONOMINAL"
};

const optionsAutocomplete = [
  {
    title: "Cached recipes",
    children: [
      {
        title: "Steak salad with mushrooms",
        url:
          "https://www.kitchenstories.com/en/recipes/steak-salad-with-mushrooms"
      },
      {
        title: "Classic creme brulee",
        url: "https://www.kitchenstories.com/en/recipes/classic-creme-brulee"
      },
      {
        title: "Carrot miso soup",
        url: "https://www.kitchenstories.com/en/recipes/carrot-miso-soup"
      },
      {
        title: "Double chocolate beetroot cake",
        url:
          "https://www.kitchenstories.com/en/recipes/double-chocolate-beetroot-cake"
      },
      {
        title: "5-ingredient chili, pork, and bean stew",
        url:
          "https://www.kitchenstories.com/en/recipes/5-ingredient-chili-pork-and-bean-stew"
      }
    ]
  }
];
class NluDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipeData: null,
      recipeUrl: "",
      features: {
        sentenceTokenizerEnabled: false,
        phraseChunkerEnabled: false,
        detailedPhraseChunkerEnabled: false,
        predictActionEnabled: false,
        nounPhraseExtractorEnabled: false,
        unknownEntityClassifierEnabled: false,
        applianceRecognizerEnabled: false,
        pronounResolutionEnabled: false
      },
      highlightSpans: {},
      loading: false,
      loadingCounter: 0,
      utensils: {},
      ingredients: {},
      needsTranslation: false
    };
  }

  setSelection = (key, e) => {
    let featuresCopy = {
      sentenceTokenizerEnabled: false,
      phraseChunkerEnabled: false,
      predictActionEnabled: false,
      nounPhraseExtractorEnabled: false,
      unknownEntityClassifierEnabled: false,
      applianceRecognizerEnabled: false,
      pronounResolutionEnabled: false
    };
    featuresCopy[key] = e.target.checked;
    this.setState({
      features: featuresCopy
    });
  };

  resetSpans = data => {
    let spansCopy = this.state.highlightSpans;
    data.method_text.forEach((it, index) => {
      spansCopy[index.toString()] = [];
    });
    this.setState({
      highlightSpans: spansCopy,
      utensils: {},
      ingredients: {}
    });
  };

  setSpan = (stepSentences, index) => {
    let spansCopy = this.state.highlightSpans;
    spansCopy[index.toString()] = stepSentences;
    this.setState({
      highlightSpans: spansCopy
    });
  };

  handleError = () => {
    message.error("Failed to fetch information from NLU API");
    this.setState({
      loading: false,
      loadingCounter: 0
    });
  };

  onFetchRecipeDetails = event => {
    event.preventDefault();

    this.props.nluApi.doFetchRecipeDetails(this.state.recipeUrl).then(
      data => {
        console.log("URL: ", data[0].url_string);
        const url = data[0].url_string;
        let needsTranslation = url.includes("chefkoch") ? true : false;
        this.setState({
          recipeData: data[0],
          needsTranslation: needsTranslation
        });
        console.log(data[0]);

        this.resetSpans(data[0]);
      },
      reason => {
        console.log("Fetching failed, reason:", reason);
        this.handleError();
      }
    );
  };

  onFetchCachedRecipeDetails = event => {
    event.preventDefault();

    this.props.nluApi.doFetchCachedRecipeDetails(this.state.recipeUrl).then(
      data => {
        this.setState({ recipeData: data[0] });

        this.resetSpans(data[0]);
      },
      reason => {
        console.log("Fetching failed, reason:", reason);
        this.handleError();
      }
    );
  };

  onTranslateRecipe = event => {
    event.preventDefault();

    this.props.nluApi.doTranslateRecipe(this.state.recipeData).then(data => {
      this.setState({
        recipeData: data,
        needsTranslation: false
      });
    });
  };

  loadingStart = () => {
    this.setState({ loading: true });
  };

  loadingStop = () => {
    let counter = this.state.loadingCounter;
    counter++;
    this.setState(
      {
        loadingCounter: counter
      },
      () => {
        if (
          this.state.recipeData.method_text &&
          counter === this.state.recipeData.method_text.length
        ) {
          this.setState({
            loading: false,
            loadingCounter: 0
          });
        }
      }
    );
  };

  onSentenceTokenizerChange = e => {
    this.setSelection("sentenceTokenizerEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doTokenizeSentence(step).then(
          response => {
            if (response[0]) {
              let stepSentences = [];
              let wordsCounter = 0;
              response[0].sentences.forEach((sentence, index) => {
                const start = wordsCounter;
                const end =
                  wordsCounter +
                  step.slice(sentence.span[0], sentence.span[1]).split(" ")
                    .length -
                  (index === response[0].sentences.length - 1 ? 0 : 1);
                wordsCounter = end;
                stepSentences.push({
                  start: start,
                  end: end,
                  tag: TAGS.SENTENCE,
                  color: TAG_COLORS.SENTENCE
                });
              });
              this.setSpan(stepSentences, index);
            }
            this.loadingStop();
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  getWordCountBeforeSpan(text, span) {
    return text.slice(0, span).split(" ").length - 1;
  }

  onPronounResolutionChange = e => {
    this.setSelection("pronounResolutionEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doPronounResolution(step).then(
          response => {
            if (response[0] && response[0].pronoun_resolution.length > 0) {
              console.log(JSON.stringify(response[0]));
              let stepSentences = [];
              let counter = 1;
              response[0].pronoun_resolution.forEach((resolution, index) => {
                const mainStart = this.getWordCountBeforeSpan(
                  response[0].text,
                  resolution.main_span[0]
                );
                const mainEnd = this.getWordCountBeforeSpan(
                  response[0].text,
                  resolution.main_span[1]
                );
                const mentionStart = this.getWordCountBeforeSpan(
                  response[0].text,
                  resolution.mention_span[0]
                );
                const mentionEnd = this.getWordCountBeforeSpan(
                  response[0].text,
                  resolution.mention_span[1]
                );

                const main = {
                  start: mainStart,
                  end: mainEnd,
                  tag: TAGS.NOMINAL,
                  color: TAG_COLORS.NOMINAL
                };

                if (stepSentences.every(it => it.start !== main.start)) {
                  stepSentences.push(main);
                }

                const mention = {
                  start: mentionStart,
                  end: mentionEnd,
                  tag: TAGS.PRONOMINAL,
                  color: TAG_COLORS.PRONOMINAL
                };
                stepSentences.push(mention);
              });
              this.setSpan(stepSentences, index);
            }
            this.loadingStop();
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  onPredictActionChange = e => {
    this.setSelection("predictActionEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doPredictAction(step).then(
          response => {
            if (response) {
              let stepSentences = [];
              response.forEach(resp => {
                resp.labels.forEach((label, index) => {
                  const wordsBefore = step.slice(0, label.span[0]).split(" ")
                    .length;
                  const start = wordsBefore - 1;
                  const end =
                    wordsBefore +
                    step.slice(label.span[0], label.span[1]).split(" ").length -
                    1;
                  stepSentences.push({
                    start: start,
                    end: end,
                    tag: label.name.toUpperCase(),
                    color: TAG_COLORS[label.name.toUpperCase()]
                  });
                });
                this.setSpan(stepSentences, index);
              });
            }
            this.loadingStop();
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  onDetailedPhraseChunkerChange = e => {
    this.setSelection("detailedPhraseChunkerEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doTokenizeSentence(step).then(
          response => {
            let sentencesFromApi = [];
            if (response[0]) {
              response[0].sentences.forEach(sentence => {
                sentencesFromApi.push(sentence.sent.replace("  ", " ")); // TODO dirty hack - to be resolved on API's side
              });
              this.props.nluApi.doChunkPhrase(sentencesFromApi).then(
                resp => {
                  // console.log(resp);
                  let counter = 0;
                  let spans = [];
                  resp[0].sentences.forEach(sentence => {
                    if (sentence.phrases.length > 0) {
                      sentence.phrases.forEach(phrase => {
                        let span = null;
                        phrase.phrase.forEach((label, index) => {
                          const wordsBefore =
                            step.slice(0, label.span[0] + counter).split(" ")
                              .length - 1;
                          const start = wordsBefore;
                          const end =
                            wordsBefore +
                            step
                              .slice(
                                label.span[0] + counter,
                                label.span[1] + counter
                              )
                              .split(" ").length;

                          if (
                            index !== phrase.phrase.length - 1 &&
                            start ===
                              step
                                .slice(
                                  0,
                                  phrase.phrase[index + 1].span[0] + counter
                                )
                                .split(" ").length -
                                1
                          ) {
                            let tag = span
                              ? span.tag +
                                " / " +
                                TAGS[
                                  phrase.phrase[index + 1].postag.toUpperCase()
                                ] +
                                " (" +
                                phrase.phrase[index + 1].text +
                                ")"
                              : TAGS[label.postag.toUpperCase()] +
                                " (" +
                                label.text +
                                ") / " +
                                TAGS[
                                  phrase.phrase[index + 1].postag.toUpperCase()
                                ] +
                                " (" +
                                phrase.phrase[index + 1].text +
                                ")";
                            span = {
                              start: start,
                              end: end,
                              tag: tag,
                              color: TAG_COLORS["COMBINED"]
                            };
                          } else {
                            if (span) {
                              spans.push(span);
                              span = null;
                            } else {
                              spans.push({
                                start: start,
                                end: end,
                                tag: TAGS[label.postag.toUpperCase()],
                                color: TAG_COLORS[label.postag.toUpperCase()]
                              });
                            }
                          }
                        });
                      });
                    }
                    counter += sentence.sentence.length;
                  });
                  this.setSpan(spans, index);
                  this.loadingStop();
                },
                reason => {
                  console.log("Fetching failed, reason:", reason);
                  this.handleError();
                }
              );
            }
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  onPhraseChunkerChange = e => {
    this.setSelection("phraseChunkerEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doTokenizeSentence(step).then(
          response => {
            let sentencesFromApi = [];
            if (response[0]) {
              response[0].sentences.forEach(sentence => {
                sentencesFromApi.push(sentence.sent.replace("  ", " ")); // TODO dirty hack - to be resolved on API's side
              });
              this.props.nluApi.doChunkPhrase(sentencesFromApi).then(
                resp => {
                  // console.log(resp);
                  let counter = 0;
                  let spans = [];
                  resp[0].sentences.forEach(sentence => {
                    if (sentence.phrases.length > 0) {
                      sentence.phrases.forEach(phrase => {
                        const wordsBefore =
                          step
                            .slice(0, phrase.phrase[0].span[0] + counter)
                            .split(" ").length - 1;
                        const start = wordsBefore;
                        const end =
                          wordsBefore +
                          step
                            .slice(
                              phrase.phrase[0].span[0] + counter,
                              phrase.phrase[phrase.phrase.length - 1].span[1] +
                                counter
                            )
                            .split(" ").length;

                        spans.push({
                          start: start,
                          end: end,
                          tag: TAGS["PHRASE"],
                          color: TAG_COLORS["PHRASE"]
                        });
                      });
                    }
                    counter += sentence.sentence.length;
                  });
                  this.setSpan(spans, index);
                  this.loadingStop();
                },
                reason => {
                  console.log("Fetching failed, reason:", reason);
                  this.handleError();
                }
              );
            }
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  onEntityClassifierCheck = e => {
    this.setSelection("unknownEntityClassifierEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doPredictEntityInstruction(step).then(
          response => {
            if (response[0]) {
              let stepSentences = [];
              let span = null;
              response[0].labels[0].entities.forEach((entity, entityIndex) => {
                const wordsBefore = step.slice(0, entity.start).split(" ")
                  .length;
                const start = wordsBefore - 1;
                const end =
                  wordsBefore +
                  step.slice(entity.start, entity.end).split(" ").length -
                  1;
                const score = JSON.stringify(entity.confidence * 100).slice(
                  0,
                  4
                );
                if (entity.entity.toUpperCase() === TAGS.UTENSIL) {
                  let copy = this.state.utensils;
                  if (!copy[index.toString()]) {
                    copy[index.toString()] = [];
                  }
                  if (!copy[index.toString()].find(e => e === entity.value)) {
                    copy[index.toString()].push(entity.value);
                    this.setState({
                      utensils: copy
                    });
                  }
                }

                if (entity.entity.toUpperCase() === TAGS.INGREDIENT) {
                  let copy = this.state.ingredients;
                  if (!copy[index.toString()]) {
                    copy[index.toString()] = [];
                  }
                  if (!copy[index.toString()].find(e => e === entity.value)) {
                    copy[index.toString()].push(entity.value);
                    this.setState({
                      ingredients: copy
                    });
                  }
                }

                if (
                  entityIndex !== response[0].labels[0].entities.length - 1 &&
                  start ===
                    step
                      .slice(
                        0,
                        response[0].labels[0].entities[entityIndex + 1].start
                      )
                      .split(" ").length -
                      1
                ) {
                  const future =
                    response[0].labels[0].entities[entityIndex + 1];
                  const futureTag = TAGS[future.entity.toUpperCase()];
                  const futureScore = JSON.stringify(
                    future.confidence * 100
                  ).slice(0, 4);
                  let tag = span
                    ? span.tag +
                      " / " +
                      futureTag +
                      " (" +
                      future.text +
                      ", " +
                      futureScore +
                      "%)"
                    : TAGS[entity.entity.toUpperCase()] +
                      " (" +
                      entity.value +
                      ", " +
                      score +
                      "%) / " +
                      futureTag +
                      " (" +
                      future.value +
                      ", " +
                      futureScore +
                      "%)";
                  span = {
                    start: start,
                    end: end,
                    tag: tag,
                    color: TAG_COLORS["COMBINED"]
                  };
                } else {
                  if (span) {
                    stepSentences.push(span);
                    span = null;
                  } else {
                    stepSentences.push({
                      start: start,
                      end: end,
                      tag:
                        TAGS[entity.entity.toUpperCase()] + " (" + score + "%)",
                      color: TAG_COLORS[entity.entity.toUpperCase()]
                    });
                  }
                }
                this.setSpan(stepSentences, index);
              });
            }
            this.loadingStop();
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  onApplianceRecognizerCheck = e => {
    this.setSelection("applianceRecognizerEnabled", e);
    this.resetSpans(this.state.recipeData);

    if (e.target.checked && this.state.recipeData.method_text) {
      this.loadingStart();
      this.state.recipeData.method_text.forEach((step, index) => {
        this.props.nluApi.doApplianceSettingExtranction(step).then(
          response => {
            if (response[0]) {
              let stepSentences = [];
              let span = null;
              console.log("RESPONSE: " + JSON.stringify(response, null, 2));
              response[0].labels[0].entities.forEach((entity, entityIndex) => {
                const wordsBefore = step.slice(0, entity.start).split(" ")
                  .length;
                const start = wordsBefore - 1;
                const end =
                  wordsBefore +
                  step.slice(entity.start, entity.end).split(" ").length -
                  1;
                const score = JSON.stringify(entity.confidence * 100).slice(
                  0,
                  4
                );
                if (entity.entity.toUpperCase() === TAGS.UTENSIL) {
                  let copy = this.state.utensils;
                  if (!copy[index.toString()]) {
                    copy[index.toString()] = [];
                  }
                  if (!copy[index.toString()].find(e => e === entity.value)) {
                    copy[index.toString()].push(entity.value);
                    this.setState({
                      utensils: copy
                    });
                  }
                }

                if (entity.entity.toUpperCase() === TAGS.INGREDIENT) {
                  let copy = this.state.ingredients;
                  if (!copy[index.toString()]) {
                    copy[index.toString()] = [];
                  }
                  if (!copy[index.toString()].find(e => e === entity.value)) {
                    copy[index.toString()].push(entity.value);
                    this.setState({
                      ingredients: copy
                    });
                  }
                }

                if (
                  entityIndex !== response[0].labels[0].entities.length - 1 &&
                  start ===
                    step
                      .slice(
                        0,
                        response[0].labels[0].entities[entityIndex + 1].start
                      )
                      .split(" ").length -
                      1
                ) {
                  const future =
                    response[0].labels[0].entities[entityIndex + 1];
                  const futureTag = TAGS[future.entity.toUpperCase()];
                  const futureScore = JSON.stringify(
                    future.confidence * 100
                  ).slice(0, 4);
                  let tag = span
                    ? span.tag +
                      " / " +
                      futureTag +
                      " (" +
                      future.text +
                      ", " +
                      futureScore +
                      "%)"
                    : TAGS[entity.entity.toUpperCase()] +
                      " (" +
                      entity.value +
                      ", " +
                      score +
                      "%) / " +
                      futureTag +
                      " (" +
                      future.value +
                      ", " +
                      futureScore +
                      "%)";
                  span = {
                    start: start,
                    end: end,
                    tag: tag,
                    color: TAG_COLORS["COMBINED"]
                  };
                } else {
                  if (span) {
                    stepSentences.push(span);
                    span = null;
                  } else {
                    stepSentences.push({
                      start: start,
                      end: end,
                      tag:
                        TAGS[entity.entity.toUpperCase()] + " (" + score + "%)",
                      color: TAG_COLORS[entity.entity.toUpperCase()]
                    });
                  }
                }
                this.setSpan(stepSentences, index);
              });
            }
            this.loadingStop();
          },
          reason => {
            console.log("Fetching failed, reason:", reason);
            this.handleError();
          }
        );
      });
    } else {
      this.resetSpans(this.state.recipeData);
    }
  };

  handleChange(value) {
    this.setState({ recipeUrl: value });
  }

  renderCommaSeparated(array, type) {
    return (
      <span>
        {" "}
        <Icon type={type} />{" "}
        {array.map((element, index) => {
          return index !== array.length - 1 ? (
            <span> {element}, </span>
          ) : (
            <span> {element} </span>
          );
        })}
      </span>
    );
  }
  render() {
    let recipeDetails;

    if (this.state.recipeData) {
      const steps = [];

      this.state.recipeData.method_text.forEach((item, index) => {
        const spans = this.state.highlightSpans[index.toString()];
        steps.push(
          <Timeline.Item key={index}>
            <TokenAnnotator
              style={{}}
              tokens={item.split(" ")}
              value={spans}
              getSpan={span => ({
                ...span
              })}
              renderMark={props => (
                <mark
                  style={{
                    border: "1px solid",
                    backgroundColor: props.color,
                    lineHeight: "1.7"
                  }}
                  key={props.key}
                >
                  {props.content}
                  <span
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "500",
                      marginLeft: "6px",
                      background: "white"
                    }}
                  >
                    {props.tag}
                  </span>
                </mark>
              )}
            />
            {this.state.features.unknownEntityClassifierEnabled ? (
              <div>
                <div>
                  {this.state.utensils[index.toString()] ? (
                    this.renderCommaSeparated(
                      this.state.utensils[index.toString()],
                      "tool"
                    )
                  ) : (
                    <div />
                  )}
                </div>
                <div>
                  {this.state.ingredients[index.toString()] ? (
                    this.renderCommaSeparated(
                      this.state.ingredients[index.toString()],
                      "experiment"
                    )
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            ) : (
              <div />
            )}
          </Timeline.Item>
        );
      });

      recipeDetails = (
        <div>
          <Row type="flex" gutter={16} style={rowStyle}>
            <Col span={12}>
              <Card title="Ingredients" style={cardStyle}>
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.recipeData.ingredient_list}
                  renderItem={item => (
                    <Row>
                      <Col span={12}>{item.amount}</Col>
                      <Col span={12}>{item.ingredient}</Col>
                    </Row>
                  )}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Features" style={cardStyle}>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.sentenceTokenizerEnabled}
                    onChange={this.onSentenceTokenizerChange}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Sententce tokenizer
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.predictActionEnabled}
                    onChange={this.onPredictActionChange}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Predict action
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.phraseChunkerEnabled}
                    onChange={this.onPhraseChunkerChange}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Phrase chunker
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.detailedPhraseChunkerEnabled}
                    onChange={this.onDetailedPhraseChunkerChange}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Detailed phrase chunker
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.unknownEntityClassifierEnabled}
                    onChange={this.onEntityClassifierCheck}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Entity classifier
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.applianceRecognizerEnabled}
                    onChange={this.onApplianceRecognizerCheck}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Appliance/Settings recognizer
                  </Checkbox>
                </Row>
                <Row style={rowStyle}>
                  <Checkbox
                    checked={this.state.features.pronounResolutionEnabled}
                    onChange={this.onPronounResolutionChange}
                    disabled={this.state.needsTranslation ? true : false}
                  >
                    Pronoun resolution
                  </Checkbox>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col span={24}>
              <Card title="Steps">
                <Timeline>{steps}</Timeline>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }

    const options = optionsAutocomplete.map(group => {
      return (
        <OptGroup key={group.title} label={group.title}>
          {group.children.map(opt => (
            <Option key={opt.url} value={opt.url}>
              {opt.title}
            </Option>
          ))}
        </OptGroup>
      );
    });

    return (
      <div>
        <h1>NLU Demo</h1>
        <Spin size="large" spinning={this.state.loading}>
          <Form onSubmit={this.onFetchRecipeDetails}>
            <FormItem>
              <div className="certain-category-search-wrapper">
                <AutoComplete
                  className="certain-category-search"
                  dropdownClassName="certain-category-search-dropdown"
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 300 }}
                  size="large"
                  style={{ width: "100%" }}
                  dataSource={options}
                  optionLabelProp="value"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.recipeUrl}
                  allowClear={true}
                  defaultActiveFirstOption={false}
                >
                  <Input placeholder="Enter recipe url" />
                </AutoComplete>
              </div>
            </FormItem>
            {this.state.needsTranslation && (
              <FormItem>
                <Alert
                  message="This recipe is in German. In order to use all NLU features,
                  please translate this recipe to English."
                  type="warning"
                  showIcon
                />
              </FormItem>
            )}
            <FormItem>
              <Button type="primary" htmlType="submit">
                <Icon type="global" />
                Fetch online recipe details
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={this.onFetchCachedRecipeDetails}
              >
                <Icon type="cloud-download" />
                Fetch cached recipe details
              </Button>
              {this.state.needsTranslation && (
                <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={this.onTranslateRecipe}
                >
                  <Icon type="sync" />
                  Translate recipe
                </Button>
              )}
            </FormItem>
          </Form>

          {recipeDetails}
        </Spin>
      </div>
    );
  }
}

export default withNluApi(NluDemo);

import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { List, Row, Col, Button, Avatar, Icon, Modal } from "antd";

import PersonalizeRecipeSteps from "../PersonalizeRecipeSteps";

import * as ROUTES from "../../constants/routes";
import { CULINARY_API } from "../../constants/apis";
import { NLG_API } from "../../constants/apis";

class RecipePage extends React.Component {
  state = {
    recipe: null,
    steps: [],
    loading: true,
    modified: false,
    modalVisible: false,
    allergens: [],
    dislikes: [],
    allDislikes: []
  };

  prepareNlgRequest(steps) {
    // prepare NLG request body
    const nlgSteps = steps.map(step => {
      // TODO!
      const containerTemplates = [
        "bowl",
        "pot",
        "pan",
        "container",
        "plate",
        "surface"
      ];
      let tools = [];
      let containers = [];
      if (step.technique && step.technique.tools) {
        for (const kitchenware of step.technique.tools) {
          let added = false;
          for (const it of containerTemplates) {
            if (kitchenware.toLowerCase().includes(it)) {
              containers.push({ name: kitchenware.toLowerCase() });
              added = true;
              break;
            }
          }
          if (!added) {
            tools.push({ name: kitchenware.toLowerCase() });
          }
        }
      }

      const prevResults = step.prevResult.map(it => {
        return { name: it };
      });

      for (const prevResult of prevResults) {
        step.ingredients.unshift(prevResult);
      }
      // TODO - needs refactor!
      return {
        "home appliance": step.technique.name === "Preheat" ? "oven" : "",
        technique:
          step.technique && step.technique.name
            ? step.technique.name.toLowerCase()
            : "",
        prestep: step.preStep ? step.preStep.name.toLowerCase() : "",
        utensils: containers,
        action_during_technique: {
          action_technique:
            step.technique && step.technique.actionDuringTechnique
              ? step.technique.actionDuringTechnique.toLowerCase()
              : "",
          tools: tools,
          interval:
            step.technique && step.technique.intervalDuringTechnique
              ? step.technique.intervalDuringTechnique.toLowerCase()
              : ""
        },
        ingredients: step.ingredients.map(ingredient => {
          return {
            name: ingredient.name.toLowerCase(),
            quantity: ingredient.quantity ? `${ingredient.quantity}` : "",
            unit: ingredient.unit ? ingredient.unit.toLowerCase() : ""
          };
        }),
        parameters: {
          time: {
            value: step.technique ? `${step.technique.duration}` : "1",
            unit: step.technique ? step.technique.unit.toLowerCase() : "min"
          },
          temperature: {
            value:
              step.technique.name === "Preheat"
                ? step.technique.setting.substring(
                    0,
                    step.technique.setting.indexOf("℃")
                  )
                : null,
            unit: step.technique.name === "Preheat" ? "℃" : null
          }
        },
        conditions: step.constraint
          ? [{ value: step.constraint.toLowerCase() }]
          : []
      };
    });
    return { actions: nlgSteps };
  }

  componentDidMount() {
    const recipeId = this.props.match.params.recipeId;
    // check if recipe is coming from recipe varians screen or customize recipe screen
    // if coming from recipe variants - modified: false - request default recipe
    // if coming from cusromize recipe - modified: true - request adapted recipe
    const modified = this.props.location.state.modified;
    this.setState({ modified: modified });

    // if recipes is coming form my recipes screen
    const myRecipe = this.props.location.state.recipe;

    // request adapted recipe
    if (myRecipe) {
      this.setState({
        recipe: myRecipe,
        steps: myRecipe.steps,
        loading: false
      });
    } else if (modified) {
      // array of ingredients & selected substitutes
      const replacements = this.props.location.state.replacements;
      const replacementsStringArray = replacements.map(
        it => `${it.ingredient.id}:${it.substitute.id}`
      );
      const replacementsString = replacementsStringArray.join(","); // TODO find out what exactly!!!
      fetch(
        `${CULINARY_API.URL}/adaptRecipe/${recipeId}/${replacementsString}`,
        {
          headers: {
            Authorization: `Basic ${btoa(
              `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
            )}`,
            Accept: "application/json"
          }
        }
      )
        .then(response => {
          return response.json();
        })
        .then(json => {
          this.setState({ recipe: json.data });
          const nlgRequestBody = this.prepareNlgRequest(json.data.steps);

          // fetch data from NLG API
          fetch(NLG_API.URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${btoa(
                `${NLG_API.USER}:${NLG_API.PASSWORD}`
              )}`
            },
            body: JSON.stringify(nlgRequestBody)
          })
            .then(response => response.json())
            .then(data => {
              const steps = data.map((step, index) => {
                step.modified = this.state.recipe.steps[index].modified;
                return step;
              });
              this.setState({ steps: steps, loading: false });
            })
            .catch(error => {
              console.error(error);
              this.setState({ loading: false });
            });
        })
        .catch(error => {
          console.error(
            "There has been a problem with your fetch operation: ",
            error.message
          );
          this.setState({ loading: false });
        });
    } else {
      // fetch recipe by ID: this.props.match.params.recipeId
      fetch(`${CULINARY_API.URL}/recipe/${recipeId}`, {
        headers: {
          Authorization: `Basic ${btoa(
            `${CULINARY_API.USER}:${CULINARY_API.PASSWORD}`
          )}`,
          Accept: "application/json"
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          this.getUserDietaryInfo(json.data).then(dietaryInfo => {
            this.setState({
              recipe: json.data,
              allergens: dietaryInfo.allergens,
              dislikes: dietaryInfo.dislikes,
              allDislikes: dietaryInfo.allDislikes,
              modalVisible:
                dietaryInfo.allergens.length > 0 ||
                dietaryInfo.dislikes.length > 0
                  ? true
                  : false
            });
            const nlgRequestBody = this.prepareNlgRequest(json.data.steps);
            // fetch data from NLG API
            fetch(NLG_API.URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${btoa(
                  `${NLG_API.USER}:${NLG_API.PASSWORD}`
                )}`
              },
              body: JSON.stringify(nlgRequestBody)
            })
              .then(response => response.json())
              .then(data => {
                const steps = data.map((step, index) => {
                  step.modified = this.state.recipe.steps[index].modified;
                  return step;
                });
                this.setState({ steps: steps, loading: false });
              })
              .catch(error => {
                console.error(error);
                this.setState({ loading: false });
              });
          });
        })
        .catch(error => {
          console.error(
            "There has been a problem with your fetch operation: ",
            error.message
          );
          this.setState({ loading: false });
        });
    }
  }

  getUserDietaryInfo = recipe => {
    return new Promise((resolve, reject) => {
      // get allergies from profile
      const { firebase } = this.props;
      const userId = firebase.auth.currentUser.uid;
      firebase.db
        .ref(`/users/${userId}`)
        .once("value")
        .then(snapshot => {
          const userObj = snapshot.val();
          const allergies = userObj.allergies
            ? Object.keys(userObj.allergies).map(key => {
                return userObj.allergies[key];
              })
            : [];

          const dislikes = userObj.dislikes
            ? Object.keys(userObj.dislikes).map(key => {
                return userObj.dislikes[key];
              })
            : [];

          const allergens = [];
          const userDislikes = [];
          for (const ingredient of recipe.ingredients) {
            for (const allergy of allergies) {
              if (ingredient.allergies.includes(allergy.name)) {
                allergens.push({
                  ingredientId: ingredient.id,
                  ingredientName: ingredient.name,
                  allergy: allergy.name
                });
              }
            }
            for (const dislike of dislikes) {
              if (ingredient.id === dislike.id) {
                userDislikes.push(dislike);
              }
            }
          }
          const dietaryInfo = { allergens: allergens, dislikes: userDislikes, allDislikes: dislikes};
          resolve(dietaryInfo);
        });
    });
  };

  handleModalOk = e => {
    this.setState({
      modalVisible: false
    });
  };

  handleModalCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  handleRightButtonClick = () => {
    if (this.state.modified) {
      let newRecipe = this.state.recipe;
      newRecipe.name = this.props.location.state.recipeName;
      newRecipe.steps = this.state.steps;
      const { firebase, authUser } = this.props;
      const newRecipeKey = firebase.db
        .ref(`/users/${authUser.uid}`)
        .child("recipes")
        .push().key;
      let updates = {};

      // *** quick fix TODO
      newRecipe.steps.map(step => {
        if (typeof step.modified === "undefined") {
          step.modified = false;
        }
        return step;
      });
      // ***

      updates[`/recipes/${newRecipeKey}`] = newRecipe;
      firebase.db.ref(`/users/${authUser.uid}`).update(updates);
    }
  };

  render() {
    const {
      recipe,
      steps,
      loading,
      modified,
      allergens,
      dislikes,
      allDislikes,
      modalVisible
    } = this.state;

    const allergiesList = modalVisible
      ? allergens.map(allergen => {
          return (
            <li key={allergen.ingredientId}>{`${
              allergen.ingredientName
            } (allergy: ${allergen.allergy})`}</li>
          );
        })
      : [];

    const dislikesList = modalVisible
      ? dislikes.map(dislike => {
          return <li key={dislike.id}>{dislike.name}</li>;
        })
      : [];

    let actionButtons = {};
    if (recipe) {
      actionButtons = modified
        ? {
            leftButtonText: "Search for new recipe",
            leftButtonPath: ROUTES.SEARCH_RECIPE,
            leftButtonIcon: "search",
            rightButtonText: "Save recipe",
            rightButtonPath: ROUTES.MY_RECIPES,
            rightButtonIcon: "heart"
          }
        : {
            leftButtonText: "Back",
            leftButtonPath: ROUTES.SEARCH_RECIPE,
            leftButtonIcon: "left",
            rightButtonText: "Personalize",
            rightButtonPath: `/customize-recipe/${recipe.id}`,
            rightButtonIcon: "edit",
            rightButtonState: {
              allergens: allergens,
              dislikes: allDislikes,
              recipeName: recipe.name
            }
          };
    }

    return (
      <div>
        <Modal
          title="Dietary info alert"
          visible={modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}>
              Cancel
            </Button>,
            <Link
              key="submit"
              to={{
                pathname: recipe ? `/customize-recipe/${recipe.id}` : "",
                state: {
                  allergens: allergens,
                  dislikes: allDislikes,
                  recipeName: recipe ? recipe.name : ""
                }
              }}
            >
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={this.handleModalOk}
              >
                Personalize
              </Button>
            </Link>
          ]}
        >
          {allergiesList.length > 0 && (
            <div>
              <p>You are allergic to:</p>
              <ul>{allergiesList}</ul>
            </div>
          )}
          {dislikesList.length > 0 && (
            <div>
              <p>According to your profile you don't like:</p>
              <ul>{dislikesList}</ul>
            </div>
          )}
          <p>Would you like to personalize this recipe?</p>
        </Modal>
        <PersonalizeRecipeSteps
          loading={loading}
          current={2}
          customizeStatus={modified ? "finish" : "wait"}
        />
        {!loading && (
          <div>
            <h1>
              {modified && this.props.location.state.recipeName
                ? this.props.location.state.recipeName
                : recipe.name}
            </h1>
            <Row gutter={16} style={{ paddingBottom: 16 }}>
              <Col span={8}>
                <List
                  header={<div>Ingredients</div>}
                  bordered
                  dataSource={recipe ? recipe.ingredients : []}
                  renderItem={ingredient => {
                    const listModified = (
                      <List.Item style={{ background: "#91d5ff" }}>
                        {`${ingredient.quantity} ${ingredient.unit} ${
                          ingredient.name
                        }`}
                      </List.Item>
                    );
                    const listNormal = (
                      <List.Item>
                        <div>
                          {`${ingredient.quantity} ${ingredient.unit} ${
                            ingredient.name
                          }`}
                          {(allergens.find(
                            it => it.ingredientId === ingredient.id
                          ) ||
                            dislikes.find(it => it.id === ingredient.id)) && (
                            <Icon
                              type="exclamation-circle"
                              style={{ color: "red", marginLeft: 10 }}
                            />
                          )}
                        </div>
                      </List.Item>
                    );
                    return ingredient.modified ? listModified : listNormal;
                  }}
                />
              </Col>
              <Col span={16}>
                <List
                  header={<div>Steps</div>}
                  bordered
                  dataSource={steps ? steps : []}
                  renderItem={step => {
                    const listModified = (
                      <List.Item style={{ background: "#91d5ff" }}>
                        <List.Item.Meta
                          avatar={<Avatar>{`${step.id + 1}`}</Avatar>}
                          title={`Step ${step.id + 1}`}
                          description={step.step}
                        />
                      </List.Item>
                    );
                    const listNormal = (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar>{`${step.id + 1}`}</Avatar>}
                          title={`Step ${step.id + 1}`}
                          description={step.step}
                        />
                      </List.Item>
                    );
                    return step.modified ? listModified : listNormal;
                  }}
                />
              </Col>
            </Row>
            {!this.props.location.state.recipe && (
              <div style={{ textAlign: "left", paddingTop: 10 }}>
                <Link to={actionButtons.leftButtonPath}>
                  <Button type="default">
                    <Icon type={actionButtons.leftButtonIcon} />
                    {actionButtons.leftButtonText}
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: actionButtons.rightButtonPath,
                    state: actionButtons.rightButtonState
                  }}
                >
                  <Button
                    type="primary"
                    onClick={this.handleRightButtonClick}
                    style={{ marginLeft: 16 }}
                  >
                    <Icon type={actionButtons.rightButtonIcon} />
                    {actionButtons.rightButtonText}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(RecipePage);
import React from "react";
import { Route } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import HomePage from "../Home";
import BuildRecipePage from "../BuildRecipe";
import SearchRecipePage from "../RecipeSearch";
import MyRecipesPage from "../MyRecipes";
import ProfilePage from "../Profile";
import NluDemoPage from "../NluDemo";
import KitchenQAPage from "../KitchenQA";
import RecipeVariantsPage from "../RecipeVariants";
import RecipePage from "../Recipe";
import CustomizeRecipePage from "../CustomizeRecipe";
import MixedRecipePage from "../MixedRecipe";

import { Layout } from "antd";
import "antd/dist/antd.css";

import Sider from "../Sider";
import Header from "../Header";

const { Content, Footer } = Layout;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    return (
      <Layout>
        <Sider />
        <Layout>
          <Header />
          <Content
            style={{
              margin: "24px 16px 0"
            }}
          >
            <div
              style={{
                padding: 24,
                background: "#fff",
                minHeight: this.state.height - 24 - 64 - 64
              }}
            >
              <Route exact path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.BUILD_RECIPE} component={BuildRecipePage} />
              <Route path={ROUTES.SEARCH_RECIPE} component={SearchRecipePage} />
              <Route path={ROUTES.MY_RECIPES} component={MyRecipesPage} />
              <Route path={ROUTES.PROFILE} component={ProfilePage} />
              <Route path={ROUTES.NLU_DEMO} component={NluDemoPage} />
              <Route path={ROUTES.KITCHEN_QA} component={KitchenQAPage} />
              <Route path={ROUTES.MIXED_RECIPE} component={MixedRecipePage} />
              <Route
                path={ROUTES.RECIPE_VARIANTS}
                component={RecipeVariantsPage}
              />
              <Route
                path={ROUTES.RECIPE}
                render={props => (
                  <RecipePage {...props} authUser={this.props.authUser} />
                )}
              />
              <Route
                path={ROUTES.CUSTOMIZE_RECIPE}
                component={CustomizeRecipePage}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: "center", height: 64 }}>
            2019 Created by CIV-TCC
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default Navigation;

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { message, Form, Icon, Input, Button, Card } from "antd";
import "antd/dist/antd.css";

const FormItem = Form.Item;

const SignInPage = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center"
    }}
  >
    <Card
      title="Sign In"
      extra={
        <div>
          or <Link to={ROUTES.SIGN_UP}>create an account</Link>
        </div>
      }
      style={{ width: 360 }}
    >
      <SignInForm />
    </Card>
  </div>
);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class SignInFormBase extends Component {
  componentDidMount() {
    // to disabled submit button at the beginning
    this.props.form.validateFields();
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.firebase
          .doSignInWithEmailAndPassword(values.email, values.password)
          .then(() => {
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            console.log(error.message);
            message.error(`Error: ${error.message}`);
          });
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const emailError = isFieldTouched("email") && getFieldError("email");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

    return (
      <Form onSubmit={this.onSubmit} style={{ maxWidth: 312 }}>
        <FormItem
          validateStatus={emailError ? "error" : ""}
          help={emailError || ""}
        >
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your e-mail!" }]
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="E-mail"
            />
          )}
        </FormItem>
        <FormItem
          validateStatus={passwordError ? "error" : ""}
          help={passwordError || ""}
        >
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
            style={{ width: "100%" }}
          >
            Sign In
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase
)(Form.create()(SignInFormBase));

export default SignInPage;

export { SignInForm };

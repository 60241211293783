import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import { Layout, Menu, Icon } from "antd";
import "antd/dist/antd.css";

const { Sider } = Layout;

class SideMenu extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div
          className="logo"
          style={{
            height: 32,
            margin: 16,
            textAlign: "center",
            fontSize: "larger",
            fontWeight: "bolder",
            color: "white"
          }}
        >
          Chef's Brain App
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to={ROUTES.HOME}>
              <Icon type="home" />
              <span className="nav-text">Home</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to={ROUTES.BUILD_RECIPE}>
              <Icon type="build" />
              <span className="nav-text">Build the recipe</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to={ROUTES.SEARCH_RECIPE}>
              <Icon type="search" />
              <span className="nav-text">Browse recipes</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to={ROUTES.MY_RECIPES}>
              <Icon type="read" />
              <span className="nav-text">My recipes</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to={ROUTES.PROFILE}>
              <Icon type="user" />
              <span className="nav-text">Profile</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to={ROUTES.NLU_DEMO}>
              <Icon type="file-search" />
              <span className="nav-text">NLU Demo</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to={ROUTES.KITCHEN_QA}>
              <Icon type="question-circle" />
              <span className="nav-text">Kitchen Q&A</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

export default SideMenu;
